:root {
  --toolbar-height: 31px;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

:root.github-dark {
  --text-color: #e6edf3; /*  sideBar.foreground */
  --bg-color: #010409; /*  sideBar.background */
  --border-color: #30363d; /*  sideBar.border */

  --toolbar-text-color: #7d8590; /*  statusBar.foreground */
  --toolbar-bg-color: #0d1117; /*  statusBar.background */

  --highlight-color: #6e768166; /*  list.activeSelectionBackground */

  --color-muted: #6e7681; /*  gitDecoration.ignoredResourceForeground */
  --color-ansi-green: #3fb950; /*  terminal.ansiGreen */
  --color-ansi-bright-green: #56d364; /*  terminal.ansiBrightGreen */
  --color-ansi-cyan: #39c5cf; /*  terminal.ansiCyan */
  --color-ansi-blue: #58a6ff; /*  terminal.ansiBlue */
  --color-ansi-bright-blue: #79c0ff; /*  terminal.ansiBrightBlue */
  --color-ansi-yellow: #d29922; /*  terminal.ansiYellow */
  --color-ansi-bright-yellow: #e3b341; /*  terminal.ansiBrightYellow */
  --color-ansi-red: #ff7b72; /*  terminal.ansiRed */
  --color-ansi-bright-red: #ffa198; /*  terminal.ansiBrightRed */
  color-scheme: dark;
}

:root.github-light {
  --text-color: #1f2328; /*  sideBar.foreground */
  --bg-color: #f6f8fa; /*  sideBar.background */
  --border-color: #d0d7de; /*  sideBar.border */

  --toolbar-text-color: #656d76; /*  statusBar.foreground */
  --toolbar-bg-color: #ffffff; /*  statusBar.background */

  --highlight-color: #afb8c133; /*  list.activeSelectionBackground */

  --color-muted: #6e7781; /*  gitDecoration.ignoredResourceForeground */
  --color-ansi-green: #116329; /*  terminal.ansiGreen */
  --color-ansi-bright-green: #1a7f37; /*  terminal.ansiBrightGreen */
  --color-ansi-cyan: #1b7c83; /*  terminal.ansiCyan */
  --color-ansi-blue: #0969da; /*  terminal.ansiBlue */
  --color-ansi-bright-blue: #218bff; /*  terminal.ansiBrightBlue */
  --color-ansi-yellow: #4d2d00; /*  terminal.ansiYellow */
  --color-ansi-bright-yellow: #633c01; /*  terminal.ansiBrightYellow */
  --color-ansi-red: #cf222e; /*  terminal.ansiRed */
  --color-ansi-bright-red: #a40e26; /*  terminal.ansiBrightRed */
  color-scheme: light;
}

:root.one-dark-pro {
  --text-color: #abb2bf; /*  sideBar.foreground */
  --bg-color: #1e2227; /*  sideBar.background */
  --border-color: #3e4452; /*  sideBar.border */

  --toolbar-text-color: #9da5b4; /*  statusBar.foreground */
  --toolbar-bg-color: #1e2227; /*  statusBar.background */

  --highlight-color: #2c313a; /*  list.activeSelectionBackground */

  --color-muted: #636b78; /*  gitDecoration.ignoredResourceForeground */
  --color-ansi-green: #8cc265; /*  terminal.ansiGreen */
  --color-ansi-bright-green: #a5e075; /*  terminal.ansiBrightGreen */
  --color-ansi-cyan: #42b3c2; /*  terminal.ansiCyan */
  --color-ansi-blue: #4aa5f0; /*  terminal.ansiBlue */
  --color-ansi-bright-blue: #4dc4ff; /*  terminal.ansiBrightBlue */
  --color-ansi-yellow: #d18f52; /*  terminal.ansiYellow */
  --color-ansi-bright-yellow: #f0a45d; /*  terminal.ansiBrightYellow */
  --color-ansi-red: #e05561; /*  terminal.ansiRed */
  --color-ansi-bright-red: #ff616e; /*  terminal.ansiBrightRed */
  color-scheme: dark;
}

:root.dracula {
  --text-color: #f8f8f2; /*  sideBar.foreground */
  --bg-color: #21222c; /*  sideBar.background */
  --border-color: #6272a4; /*  sideBar.border */

  --toolbar-text-color: #f8f8f2; /*  statusBar.foreground */
  --toolbar-bg-color: #191a21; /*  statusBar.background */

  --highlight-color: #44475a; /*  list.activeSelectionBackground */

  --color-muted: #6272A4; /*  gitDecoration.ignoredResourceForeground */
  --color-ansi-green: #50fa7b; /*  terminal.ansiGreen */
  --color-ansi-bright-green: #69ff94; /*  terminal.ansiBrightGreen */
  --color-ansi-cyan: #8be9fd; /*  terminal.ansiCyan */
  --color-ansi-blue: #bd93f9; /*  terminal.ansiBlue */
  --color-ansi-bright-blue: #d6acff; /*  terminal.ansiBrightBlue */
  --color-ansi-yellow: #f1fa8c; /*  terminal.ansiYellow */
  --color-ansi-bright-yellow: #ffffa5; /*  terminal.ansiBrightYellow */
  --color-ansi-red: #ff5555; /*  terminal.ansiRed */
  --color-ansi-bright-red: #ff6e6e; /*  terminal.ansiBrightRed */
  color-scheme: dark;
}

*,
:before,
:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 16px;
}

body {
  max-width: 100dvw;
  max-height: 100dvh;
  overflow: hidden;
  line-height: 1.5;
  color: var(--text-color);
  font-family: var(--font-family);
  background-color: var(--bg-color);
}

a {
  color: var(--text-color);
}

a:hover {
  text-decoration: none;
}
