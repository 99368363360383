.container {
  position: fixed;
  top: 50%;
  left: 50%;
  border: 0;
  width: calc(100% / 3);
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}

@media all and (min-width: 0px) and (max-width: 481px) {
  .container {
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.3rem;
  font-weight: 600;
  padding-bottom: 1rem;
  align-items: center;
}

.close {
  color: var(--color-muted);
  border: 0;
  line-height: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.close > svg {
  margin: 0;
  vertical-align: text-top;
}

::backdrop {
  background-color: rgba(0, 0, 0, 0.75);
}
