.container {
  font-size: 0.75rem;
  line-height: 10px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--toolbar-text-color);
  background-color: var(--toolbar-bg-color);
  border-top: 1px solid var(--border-color);
  height: var(--toolbar-height);
}

.container > div {
  margin-top: -1px;
}

.container a {
  color: var(--toolbar-text-color);
  text-decoration: none;
}

.container a > svg {
  margin: 0;
  vertical-align: text-top;
}

.container > div {
  width: 33%;
}

.container > div:first-child {
  text-align: left;
}

.tools {
  text-align: right;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  justify-content: flex-end;
}

@media all and (min-width: 0px) and (max-width: 481px) {
  .container > div:first-child {
    width: auto;
  }

  .center {
    display: none;
  }

  .container > div:last-child {
    width: auto;
  }
}

.container button {
  border: 0;
  font-size: inherit;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: var(--toolbar-text-color);
}

.container button > svg {
  margin: 0;
  vertical-align: text-top;
}

.icon {
  vertical-align: text-bottom;
}

.error,
.warning {
  margin-right: 0.25rem;
}

.clear {
  top: -1px;
  position: relative;
}

.run {
  color: var(--color-ansi-bright-green);
}

.warning {
  color: var(--color-ansi-bright-yellow);
}

.error {
  color: var(--color-ansi-bright-red);
}

.loading {
  animation-name: spin;
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.dropdown {
  position: relative;
  display: inline-block;
  background: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0;
  outline: none;
  top: -1px;
}

.center {
  text-align: center;
}

.center > a {
  display: inline-block;
  opacity: 0.75;
  background-color: var(--border-color);
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  border-radius: 3px;
}

.center > a:hover {
  opacity: 1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
