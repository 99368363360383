.container {
  height: 100%;
  overflow: auto;
  position: relative;
}

.logs {
  margin: 0;
  list-style: none;
  padding: 0.5rem 1rem;
  font-family: 'Fira Code', 'Fira Mono', Menlo, Consolas, 'DejaVu Sans Mono',
    monospace;
}

.logs > li {
  padding: 0.25rem;
  border-bottom: 1px solid var(--border-color);
  opacity: 0.75;
  display: block;
  line-height: 1;
  animation-iteration-count: initial;
  animation-name: highlight;
  animation-duration: 1s;
}

.logs > li.warn {
  background-color: rgb(240 164 93 / 5%);
}

.logs > li.warn .icon {
  color: var(--color-ansi-bright-yellow);
}

.logs > li.error {
  background-color: rgba(255 97 110 / 5%);
}

.logs > li.error .icon {
  color: var(--color-ansi-bright-red);
}

.icon {
  vertical-align: top;
  margin-right: 0.5rem;
  position: relative;
  top: 2px;
}

@keyframes highlight {
  from {
    background-color: var(--highlight-color);
  }
}

.help {
  padding: 1rem;
  font-size: 0.875rem;
  background-color: var(--toolbar-bg-color);
  width: calc(100% / 2);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  color: var(--toolbar-text-color);
}

@media all and (min-width: 0px) and (max-width: 481px) {
  .help {
    width: calc(100% - 2rem);
    margin: 1rem;
    left: 0;
    top: 0;
    transform: none;
  }

  .container {
    border-bottom: 1px solid var(--border-color);
  }
}

.help ul {
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0 1rem;
}

.help ul > li {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--border-color);
}

.help kbd {
  border-radius: 3px;
  border: 1px solid var(--border-color);
  background-color: var(--highlight-color);
  display: inline-block;
  font-size: 0.85em;
  font-weight: 600;
  line-height: 1;
  padding: 3px 6px;
  white-space: nowrap;
}

.help p {
  color: var(--color-muted);
}
