.container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.container ul > li {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--border-color);
}

.container kbd {
  border-radius: 3px;
  border: 1px solid var(--border-color);
  background-color: var(--highlight-color);
  color: var(--text-color);
  display: inline-block;
  font-size: 0.85em;
  font-weight: 600;
  line-height: 1;
  padding: 3px 6px;
  white-space: nowrap;
}

.container strong {
  font-size: 1rem;
}

.container p {
  font-size: 0.9375rem;
  margin: 1.25rem 0 1rem;
}
