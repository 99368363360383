.container {
  font-size: 0.75rem;
  line-height: 12px;
  padding: 0.5rem 1rem;
  color: #ffffff;
  background-color: #cc6633;
  border-top: 1px solid #ec8d5d;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.container button {
  border: 0;
  line-height: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #ffffff;
}

.container button > svg {
  margin: 0;
  vertical-align: text-top;
}
