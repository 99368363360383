.form {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.form fieldset {
  border: 0;
}

.form label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-color);
}

.form select,
.form input:not([type='checkbox']) {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border-radius: 3px;
  font-size: 0.875rem;
  color: var(--text-color);
  background-color: var(--highlight-color);
  border: 0;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1),
    0px 0px 0px 1px var(--border-color);
}

.form select {
  border-right: 8px solid transparent;
}

.form button {
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  background-color: var(--highlight-color);
}

.form button:hover {
  background-color: var(--border-color);
}

.form button + button {
  margin-left: 0.5rem;
}
