.container {
  display: flex;
  background-color: var(--bg-color);
  height: calc(100dvh - var(--toolbar-height));
}

.resize {
  opacity: 0.5;
  position: relative;
  background-color: var(--border-color);
}

.horizontal {
  cursor: col-resize !important;
  width: 5px;
  height: 100%;
  margin-left: -1px;
}

.vertical {
  cursor: row-resize !important;
  width: 100%;
  height: 5px;
  margin-top: -1px;
}

.resize:hover {
  opacity: 1;
}

@media all and (min-width: 0px) and (max-width: 481px) {
  .container > div {
    flex-direction: column-reverse !important;
  }

  .resize {
    display: none;
  }
}
